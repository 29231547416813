/**** Form elements *****/

.form-group {
  margin-bottom: 20px;
}
.form-control {
  border: 1px solid #fff;
  background: #fff;
  border-radius: 20px;
  display: block;
  width: 100%;
  padding: 5px 20px 4px;
  line-height: 20px;
  height: 50px;
  font-size: 16px;
}

textarea.form-control {
  height: 71px;
}

// .select-container {
//   position: relative;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   background: #fff;
//   max-width: 100%;
//   overflow: hidden;
//   height: 40px;
//   display: flex;
//   align-items: stretch;
//   transition: all 0.3s;
//   &.focused {
//     border-color: #aaa;
//   }
//   @include max($xxxs) {
//     width: 100%;

//   }
//   &:after {
//     content: "";
//     display: block;
//     position: absolute;
//     right: 10px;
//     top: 13px;
//     width: 17px;
//     height: 10px;
//     background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173.8 98.6"><path class="a" d="M96.8,98.6,183.8,0H10Z" transform="translate(-10 0)"/></svg>') 50% 50% no-repeat;
//     background-size: contain;
//   }
//   select {
//     appearance: none;
//     border: none;
//     background: none;
//     font-size: 16px;
//     font-weight: normal;
//     padding: 8px 40px 8px 12px;
//     height: 38px;
//     line-height: 22px;
//     width: 100%;
//     @include max($xxxs) {
//       height: 30px;
//     }
//   }
// }

// .form-check {
//   margin-bottom: 10px;
// }
// .custom-checkbox {
//   input[type="checkbox"] {
//     position: absolute;
//     opacity: 0;
//     z-index: -1;
//     + span {
//       position: relative;
//       padding: 0 0 0 30px;
//       display: block;
//       &:before {
//         content: '';
//         border: 1px solid #000;
//         box-shadow: 0 1px 1px rgba(0, 0, 0, .2) inset;
//         border-radius: 4px;
//         background: #E5E5E5;
//         width: 16px;
//         height: 16px;
//         position: absolute;
//         left: 0;
//         top: 1px;
//         box-sizing: border-box;
//       }
//     }
//     &:checked {
//       + span {
//         &:before {

//         }
//         &:after {
//           content: "";
//           display: block;
//           position: absolute;
//           top: 5px;
//           left: 2px;
//           width: 11px;
//           height: 8px;
//           background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7.96"><path d="M4.27,5,8.93.33a1.23,1.23,0,0,1,1.74,1.74L5.14,7.6a1.23,1.23,0,0,1-1.74,0L.33,4.52A1.23,1.23,0,0,1,2.07,2.79Z"/></svg>');
//           background-size: contain;
//           @include red();
//         }
//       }
//     }
//     &:focus {
//       + span {
//         &:before {
//           background: #f5f5f5;
//         }
//       }
//     }
//     &:not(:disabled) {
//       span {
//         &:hover {
//           &:before {
//             background: #f5f5f5;
//           }
//         }
//       }
//     }
//   }
// }

// .custom-radio {
//   input[type="radio"] {
//     position: absolute;
//     opacity: 0;
//     z-index: -1;
//     + span {
//       position: relative;
//       padding: 0 0 0 28px;
//       display: block;
//       &:before {
//         content: '';
//         display: block;
//         border: 1px solid #000;
//         border-radius: 50%;
//         background: #E5E5E5;
//         box-shadow: 1px 1px 0 #A6A6A6 inset;
//         width: 16px;
//         height: 16px;
//         position: absolute;
//         left: 0;
//         top: 2px;
//         box-sizing: border-box;
//       }
//       &:after {
//         content: "";
//         display: none;
//         position: absolute;
//         left: 4px;
//         top: 6px;
//         width: 8px;
//         height: 8px;
//         background: $red;
//         border-radius: 50%;
//         z-index: 1;
//       }
//     }
//     &:checked {
//       + span {
//         &:before {

//         }
//         &:after {
//           display: block;
//         }
//       }
//     }
//     &:focus {
//       + span {
//         &:before {
//           background: #f5f5f5;
//         }
//       }
//     }
//     &:not(:disabled) {
//       span {
//         &:hover {
//           &:before {
//             background: #f5f5f5;
//           }
//         }
//       }
//     }
//   }

//   input[type="radio"] {
//     & + span.radio-info {
//       font-weight: normal;
//       &:before {
//         top: 4px;
//       }
//       &:after {
//         top: 8px;
//       }
//       .radio-info-h {
//         display: flex;
//         align-items: center;
//         margin-bottom: 5px;
//         color: #000;
//         font-size: 19px;
//         font-weight: normal;
//         line-height: 24px;
//         span {}
//         i.icon {
//           margin-left: 7px;
//         }
//       }
//       .radio-info-desc {
//         display: block;
//         font-size: 16px;
//         color: $dgrey;
//       }
//     }
//   }
// }

/* Selectric */

// .selectric {
//   border-color: transparent;
//   background: none;
//   .label {
//     font-size: 24px;
//     color: $black;
//     margin: 0 38px 0 0;
//     font-weight: 500;
//     @include max($xxl) {
//       font-size: 20px;
//     }
//   }
//   .button {
//     background: none;
//     transition: all 0.1s;
//     &:after {
//       border: none;
//       width: 38px;
//       height: 38px;
//       background: url("../images/icons/dropdown.svg") 50% 50% no-repeat;
//       background-size: 11px 7px;
//     }
//   }
// }
// .selectric-items {
//   background: #f3f3f3;
//   border: none;
//   border-radius: 0;
//   ul {
//     li {
//       font-size: 20px;
//       white-space: nowrap;
//       transition: all 0.1s;
//       @include max($xxl) {
//         font-size: 16px;
//       }
//       &:hover {
//         background: #eee;
//       }
//       &.selected {
//       }
//       &.highlighted {
//         background: #ddd;
//       }
//     }
//   }
// }
// .selectric-wrapper.selectric-hover,
// .selectric-wrapper.selectric-open,
// .selectric-wrapper.selectric-focus {
//   .selectric {
//     border-color: transparent;
//   }
// }
// .selectric-wrapper.selectric-default {
//   .selectric {
//     .label {
//       color: #979797;
//     }
//     .button {
//       filter: invert(81%) sepia(0%) saturate(125%) hue-rotate(142deg)
//         brightness(75%) contrast(84%);
//     }
//   }
// }
