$blue: #1898ed;
$bluehover: #2baaff;
$red: #ff5000;
$redhover: #fd6c33;
$brown: #ce5800;
$brownhover: #db6d19;
$green: #42704e;
$greenhover: #50815d;
$lgrey: #e4e5e6;
$dark: #272727;
$black: #000;

$font-heading: "Roboto", sans-serif;
$font-basic: "Roboto", sans-serif;

$xxxs: 360px;
$xxs: 400px;
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 1025px;
$xl: 1200px;
$xxl: 1280px;
$xxxl: 1400px;
$huge: 1600px;
$xhuge: 1800px;
$xxhuge: 2000px;
