/***** Simple elements, atoms *****/

.icon {
  fill: currentColor;
  max-height: 100%;
  max-width: 100%;
  overflow: visible;
}

.highlight {
  color: $red;
}

[data-aos="background"] {
  &:after {
    opacity: 0;
    width: 0;
    transition: all 1s;
  }
  &.is-aos {
    &:after {
      opacity: 1;
      width: 100%;
    }
  }
}

[data-aos="fade-up"] {
  transform: translateY(40px);
  opacity: 0;
  transition: all 0.5s;
  &.is-aos {
    opacity: 1;
    transform: none;
  }
}
