/***** Header *****/

.header {
  padding: 20px 0;
  height: 95px;
  background: $dark;
  border-radius: 0 0;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 2px 4px 16px rgba(#000, 0.05);
  transition: all 0.2s;
  z-index: 10;
  @include max($huge) {
    height: 65px;
  }
  @include max($lg) {
    height: 50px;
    padding: 10px 0;
  }
  .header-i {
    display: flex;
    align-items: center;
    .logo {
      line-height: 0;
      color: #fff;
      transition: all 0.2s;
      img {
        transition: all 0.2s;
        height: 40px;
        @include max($lg) {
          height: 30px;
        }
      }
    }

    .header-r {
      margin-left: auto;
      @include max($lg) {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        top: 50px;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $dark;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .header-nav {
      display: flex;
      align-items: center;
      gap: 24px;
      @include max($lg) {
        flex-direction: column;
      }
      .btn {
      }
    }

    .toggler {
      display: none;
      margin-left: auto;
      width: 36px;
      height: 36px;
      position: relative;
      cursor: pointer;
      @include max($lg) {
        display: block;
      }
      .icon {
        position: absolute;
        overflow: visible;
        margin: auto;
        fill: #fff;
        transition: all 0.1s;
        &.icon-menu {
          width: 36px;
          height: 19px;
          margin-top: 8px;
        }
        &.icon-cross {
          opacity: 0;
          visibility: hidden;
          width: 24px;
          height: 24px;
          margin: 6px;
        }
      }
      &:hover {
        .icon {
          opacity: 0.8;
        }
      }
    }
  }
}

body.is-scrolled {
  .main {
    // margin-top: 65px;
  }
  .header {
    padding: 5px 0;
    height: 65px;
    background: rgba(#000, 0.7);
    @include max($lg) {
      height: 50px;
    }
    .logo {
    }
  }
}
body.is-menu {
  overflow: hidden;
  .header {
    .header-r {
      opacity: 1;
      visibility: visible;
    }
    .toggler {
      .icon {
        &.icon-menu {
          opacity: 0;
          visibility: hidden;
        }
        &.icon-cross {
          opacity: 1;
          visibility: visible;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
