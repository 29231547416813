/***** Homepage *****/

.main > section {
  position: relative;
  z-index: 1;
}

.card.card--white {
  background: #fff;
  border-radius: 20px;
  box-shadow: 2px 4px 16px rgba(#000, 0.05);
}

.section-top {
  margin-bottom: 126px;
  @include max($huge) {
    margin-bottom: 60px;
  }
  @include max($lg) {
    margin-bottom: 40px;
  }
  .top-i {
    display: flex;
    justify-content: flex-start;
    .card.card--top {
      max-width: 760px;
      padding: 70px 60px 60px;
      @include max($huge) {
        padding: 60px 30px;
      }
      @include max($lg) {
        width: 100%;
        padding: 30px 20px;
        text-align: center;
      }
      h1 {
        color: $green;
        --bc: #fd6c33;
        --d: 0.3s;
      }

      .txt {
        margin-bottom: 53px;
        --bc: #2baaff;
        --d: 0.8s;
        @include max($lg) {
          margin-bottom: 30px;
        }
        p {
        }
      }

      .block-reveal {
        --td: 1s;
        --t: calc(var(--td) + var(--d));
        color: transparent;
        position: relative;
        overflow: hidden;
        animation: revealBlock 0s var(--t) forwards;
        &:after {
          content: "";
          width: 0%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: var(--bc);
          animation: revealingIn var(--td) var(--d) forwards,
            revealingOut var(--td) var(--t) forwards;
        }
      }

      .btns {
        display: flex;
        justify-content: center;
        .btn {
        }
      }
    }
  }
}

@keyframes revealBlock {
  100% {
    color: #0f0f0f;
  }
}

@keyframes revealingIn {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes revealingOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

.section-features {
  margin-bottom: 36px;
  @include max($huge) {
    margin-bottom: 40px;
  }
  .card.card--features {
    padding: 80px 120px 100px;
    @include max($huge) {
      padding: 60px 30px;
    }
    @include max($lg) {
      padding: 30px 20px;
    }
    h2 {
      text-align: center;
      // font-size: 36px;
      color: $green;
      margin-bottom: 70px;
      @include max($huge) {
        margin-bottom: 40px;
      }
      @include max($lg) {
        margin-bottom: 20px;
      }
    }
  }
  .feature-list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 -50px -50px;
    @include max($huge) {
      margin: 0 -30px -40px;
    }
    @include max($lg) {
      margin: 0 -20px -40px;
    }
    .feature-item {
      width: 50%;
      flex: 0 0 50%;
      padding: 0 50px 50px;
      @include max($huge) {
        padding: 0 30px 40px;
      }
      @include max($lg) {
        padding: 0 20px 40px;
        width: 100%;
        flex: 0 0 100%;
      }
      @include max($md) {
      }
      &.feature-item--btn {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          min-width: 457px;
          @include max($huge) {
            min-width: 0;
          }
        }
      }
    }
  }
}

.section-brown {
  margin-bottom: 40px;
  .card.card--brown {
    box-shadow: 2px 4px 16px rgba(#000, 0.05);
    color: #fff;
    display: flex;
    padding: 60px 60px 75px;
    position: relative;
    @include max($huge) {
      padding: 40px 30px;
    }
    @include max($lg) {
      padding: 30px 20px;
    }
    &:after {
      content: "";
      border-radius: 20px;
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background: $brown;
    }
    &.is-aos {
      &:after {
        width: 100%;
        opacity: 1;
      }
    }
  }
  .brown-i {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    @include max($lg) {
      display: block;
      text-align: center;
    }
  }

  h2 {
    width: 600px;
    flex: 0 0 600px;
    padding-right: 50px;
    margin-right: 190px;
    @include max($huge) {
      width: 50%;
      flex: 0 0 50%;
      margin-right: 40px;
      padding-right: 40px;
    }
    @include max($lg) {
      width: 100%;
      margin: 0 0 20px;
      padding: 0;
    }
  }

  .brown-body {
    flex: 1;
    .txt {
      font-size: 28px;
      margin-bottom: 48px;
      @include max($huge) {
        font-size: 24px;
        margin-bottom: 40px;
      }
      @include max($lg) {
        font-size: 20px;
        margin-bottom: 30px;
      }
      p {
      }
    }

    .btns {
      display: flex;
      justify-content: center;
      .btn {
        min-width: 457px;
        @include max($huge) {
          min-width: 0;
        }
      }
    }
  }
}

.section-slider {
  margin-bottom: 60px;
  @include max($lg) {
    margin-bottom: 40px;
  }
  .swiper {
  }

  .swiper-wrapper {
    height: auto;
  }
  .swiper-slide {
    width: 1560px;
    height: auto;
    display: flex;
    align-items: stretch;
    @include max($huge) {
      width: 80vw;
    }
    @include max($lg) {
      width: 80vw;
    }
    .card {
    }
  }
}

.section-btn {
  margin-bottom: 60px;
  text-align: center;

  .btn {
  }
}

.section-green {
  position: relative;
  padding: 80px 0;
  color: #fff;
  @include max($huge) {
    padding: 60px 30px;
  }
  @include max($lg) {
    padding: 40px 0;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background: $green;
  }
  &.is-aos {
    &:after {
      width: 100%;
      opacity: 1;
    }
  }
  .green-i {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    @include max($lg) {
      display: block;
      text-align: center;
    }
  }
  h2 {
    width: 600px;
    flex: 0 0 600px;
    padding: 0 50px 0 100px;
    margin-right: 190px;
    @include max($huge) {
      width: 50%;
      flex: 0 0 50%;
      padding: 0 40px 0 0;
      margin: 0 40px 0 0;
    }
    @include max($lg) {
      width: 100%;
      margin: 0 0 20px;
      padding: 0;
    }
  }

  .green-body {
    flex: 0 0 580px;
    width: 580px;
    margin-left: auto;
    @include max($huge) {
      margin: 0;
      flex: 1;
      width: auto;
    }
    .txt {
      font-size: 28px;
      margin-bottom: 48px;
      @include max($huge) {
        font-size: 24px;
        margin-bottom: 40px;
      }
      @include max($lg) {
        font-size: 20px;
        margin-bottom: 30px;
      }
      p {
      }
    }

    .btns {
      display: flex;
      justify-content: center;
      .btn {
        min-width: 457px;
        @include max($huge) {
          min-width: 0;
        }
      }
    }
  }
}

.section-membership {
  background: #fff;
  min-height: 803px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 60px;
  @include max($huge) {
    display: flex;
    align-items: center;
    min-height: 0;
    padding: 20px 0;
  }
  @include max($lg) {
    flex-direction: column;
    padding: 40px 0;
  }
  .container {
    display: flex;
    align-items: center;
    @include max($lg) {
      flex-direction: column;
    }
  }
  .membership-body {
    padding: 0 50px 0 100px;
    // max-width: 700px;
    width: 50%;
    flex: 0 0 50%;
    @include max($huge) {
      padding: 0 50px 0 50px;
    }
    @include max($lg) {
      padding: 0 0 20px;
      text-align: center;
      width: 100%;
    }
  }
  h2 {
    color: $brown;
    margin-bottom: 60px;
    @include max($huge) {
      margin-bottom: 40px;
    }
    @include max($lg) {
      margin-bottom: 20px;
    }
  }

  .btn {
    min-width: 457px;
    @include max($huge) {
      min-width: 0;
    }
  }

  .membership-img {
    // position: absolute;
    // right: 0;
    // top: 50%;
    // transform: translateY(-50%);
    img {
    }
    @include max($huge) {
      // position: relative;
      // top: auto;
      // transform: none;
    }
  }
}

.section-info {
  margin-bottom: 50px;
  .info-i {
    .card {
    }
  }
}

.section-signup {
  margin-bottom: 40px;

  .signup-i1 {
    position: relative;
    z-index: 1;

    box-shadow: 2px 4px 16px rgba(#000, 0.05);
    color: #fff;
    text-align: center;
    padding: 57px 60px 52px;
    @include max($huge) {
      padding: 40px 30px;
    }
    @include max($lg) {
      padding: 30px 20px;
    }
    &:after {
      content: "";
      background: $green;
      border-radius: 20px;
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
    }
    &.is-aos {
      &:after {
        width: 100%;
        opacity: 1;
      }
    }
    .signup-i2 {
      position: relative;
      z-index: 1;
    }
  }
  h2 {
    margin-bottom: 20px;
  }

  .txt {
    font-size: 28px;
    margin: 0 auto 40px;
    max-width: 840px;
    @include max($huge) {
      font-size: 24px;
    }
    @include max($lg) {
      font-size: 20px;
      margin-bottom: 30px;
    }
    p {
    }
  }

  .signup-form {
    display: flex;
    align-items: center;
    justify-content: center;
    @include max($lg) {
      flex-direction: column;
      align-items: stretch;
      max-width: 600px;
      margin: 0 auto;
    }
    .form-group {
      margin: 0 12px 0 0;
      @include max($lg) {
        margin: 0 0 10px;
      }
      input {
        @include max($lg) {
          width: 100%;
        }
        &.form-control {
        }
      }
    }

    .btn {
      margin-left: 32px;
      @include max($lg) {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.section-clubs {
  .clubs-i {
    background: #fff;
    padding: 100px 160px;
    border-radius: 20px;
    box-shadow: 2px 4px 16px rgba(#000, 0.05);
    @include max($huge) {
      padding: 60px;
    }
    @include max($lg) {
      padding: 30px 20px;
    }
  }
  .clubs-h {
    margin-bottom: 70px;
    display: flex;
    align-items: center;
    color: $green;
    gap: 14px;
    @include max($huge) {
      margin-bottom: 40px;
    }
    @include max($lg) {
      margin-bottom: 30px;
    }
    h2 {
      margin: 0;
    }
  }

  .club-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -50px -50px;
    @include max($huge) {
      margin: 0 -30px -40px;
    }
    @include max($lg) {
      margin: 0 -20px -40px;
    }
    .club-item {
      width: 50%;
      flex: 0 0 50%;
      padding: 0 50px 50px;
      @include max($huge) {
        padding: 0 30px 40px;
      }
      @include max($lg) {
        padding: 0 20px 40px;
        width: 100%;
        flex: 0 0 100%;
      }
      .card {
      }
    }
  }

  .btns {
    margin-top: 130px;
    display: flex;
    justify-content: center;
    @include max($huge) {
      margin-top: 60px;
    }
    @include max($lg) {
      margin-top: 40px;
    }
    .btn {
    }
  }
}

.video-bg {
  position: fixed;
  transition: all 0.2s;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
