/***** Buttons *****/

.btn {
  cursor: pointer;
  transition: all 0.2s;
  display: inline-block;
  text-align: center;
  border-radius: 20px;
  padding: 6px 20px 5px;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  min-width: 128px;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.4) 70%,
      rgba(255, 255, 255, 0) 70.1%
    );
    // box-shadow: 0px 0px 8px 4px #fff;
    transform: translateX(-100%);
  }
  &:hover {
    &:after {
      animation: sweep 1s;
    }
  }
  &.btn-link {
    color: #fff;
    text-decoration: underline;
    line-height: 33px;
    height: 44px;
    min-width: 100px;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
      color: #eee;
    }
  }
  &.btn-red {
    background: $red;
    color: #fff;
    &:hover {
      background: $redhover;
    }
  }
  &.btn-brown {
    background: $brown;
    color: #fff;
    &:hover {
      background: $brownhover;
    }
  }
  &.btn-blue {
    background: $blue;
    color: #fff;

    &.btn-icon {
      span {
        // color: $lgrey;
      }
    }
    &:hover {
      background: $bluehover;
      &.btn-icon {
        span {
          color: #fff;
        }
      }
    }
  }
  &.btn-green {
    background: $green;
    color: #fff;
    &:hover {
      background: $greenhover;
    }
  }
  &.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    span {
      transition: all 0.2s;
    }
  }
  &.btn-38 {
    height: 38px;
    line-height: 27px;
    font-size: 16px;
  }
  &.btn-44 {
    line-height: 33px;
    height: 44px;
  }
  &.btn-50 {
    line-height: 39px;
    height: 50px;
  }
  &.btn-60 {
    line-height: 49px;
    height: 60px;
    padding: 6px 50px 5px;
    font-size: 24px;
    @include max($huge) {
      font-size: 22px;
      height: 56px;
      line-height: 45px;
    }
    @include max($lg) {
      font-size: 20px;
      height: 54px;
      line-height: 43px;
      padding: 6px 20px 5px;
    }
  }
  &.btn-68 {
  }
}
.btns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 -4px;
  .btn {
    margin: 0 8px 4px 0;
    &:last-child {
      margin-right: 0;
    }
  }
  &.btns-centered {
    justify-content: center;
  }
}

@keyframes sweep {
  0% {
    transform: translateX(-100%);
    opacity: 0;
    animation-timing-function: ease-in;
  }
  50% {
    transform: translateX(0);
    opacity: 0.5;
    animation-timing-function: linear;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
    animation-timing-function: ease-out;
  }
}
