/***** Components, common blocks *****/

.card.card--feature {
  display: flex;
  align-items: center;
  .feature-img {
    width: 145px;
    flex: 0 0 145px;
    margin-right: 30px;
    line-height: 0;
    @include max($huge) {
      margin-right: 20px;
    }
    @include max($lg) {
      width: 120px;
      flex: 0 0 120px;
    }
    @include max($sm) {
      width: 100px;
      flex: 0 0 100px;
    }
    img {
    }
  }

  .feature-body {
    flex: 1;
    h3 {
    }

    .feature-desc {
    }
  }
}

.card.card--info {
  background: #fff;
  border-radius: 20px;
  box-shadow: 2px 4px 16px rgba(#000, 0.05);
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  @include max($huge) {
  }
  @include max($lg) {
    border-radius: 10px;
    flex-direction: column;
    text-align: center;
  }
  .card-body {
    flex: 1;
    .h2 {
      color: $green;
    }

    .card-txt {
      font-size: 28px;
      @include max($huge) {
        font-size: 24px;
      }
      @include max($lg) {
        font-size: 20px;
      }
      p {
      }
    }
    .btns {
      margin-top: 90px;
      display: flex;
      justify-content: center;
      @include max($huge) {
        margin-top: 60px;
      }
      @include max($lg) {
        margin-top: 30px;
      }
    }
  }

  .card-img {
    width: 836px;
    flex: 0 0 836px;
    @include max($huge) {
    }
    @include max($huge) {
      width: 50%;
      flex: 0 0 50%;
    }
    @include max($lg) {
      width: 100%;
      flex: 1;
      max-width: 500px;
      margin: 0 auto;
      order: 1;
    }
    img {
      width: 100%;
    }
  }
  &.card--info--v1 {
    padding: 20px 80px 20px 0;
    @include max($huge) {
      padding: 20px 40px 20px 0;
    }
    @include max($lg) {
      padding: 30px;
    }
    @include max($sm) {
      padding: 20px;
    }
    .card-body {
      order: 2;
    }
    .card-img {
      margin-right: 115px;
      order: 1;
      @include max($huge) {
        margin-right: 80px;
      }
      @include max($lg) {
        margin: 0 0 20px;
      }
    }
  }
  &.card--info--v2 {
    padding: 20px 0 20px 80px;
    background: #fff url("../images/yellow-bg.jpg") 0 0 repeat;
    @include max($huge) {
      padding: 20px 0 20px 40px;
    }
    @include max($lg) {
      padding: 30px;
    }
    @include max($sm) {
      padding: 20px;
    }
    .card-body {
      @include max($lg) {
        order: 2;
      }
    }
    .card-img {
      margin-left: 70px;
      @include max($huge) {
        margin-left: 40px;
      }
      @include max($lg) {
        order: 1;
        margin: 0 0 20px;
      }
    }
  }
  &.card--info--v3 {
    padding: 20px 80px 20px 0;
    background: #fff url("../images/yellow-bg.jpg") 0 0 repeat;
    @include max($huge) {
      padding: 20px 40px 20px 0;
    }
    @include max($lg) {
      padding: 30px;
    }
    @include max($sm) {
      padding: 20px;
    }
    .card-body {
      order: 2;
      h2 {
        color: $brown;
      }
    }
    .card-img {
      margin-right: 115px;
      order: 1;
      @include max($huge) {
        margin-right: 80px;
      }
      @include max($lg) {
        margin: 0 0 20px;
      }
    }
  }
  &.card--info--v4 {
    padding: 20px 0 20px 80px;
    @include max($huge) {
      padding: 20px 0 20px 40px;
    }
    @include max($lg) {
      padding: 30px;
    }
    @include max($sm) {
      padding: 20px;
    }
    .card-body {
      @include max($lg) {
        order: 2;
      }
      h2 {
        color: $brown;
      }
    }
    .card-img {
      margin-left: 70px;
      @include max($huge) {
        margin-left: 40px;
      }
      @include max($lg) {
        margin: 0 0 20px;
        order: 1;
      }
    }
  }
}

.txt {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.card.card--club {
  display: flex;
  align-items: center;
  .card-img {
    width: 145px;
    flex: 0 0 145px;
    margin-right: 68px;
    @include max($huge) {
      margin-right: 30px;
      width: 120px;
      flex: 0 0 120px;
    }
    @include max($lg) {
      margin-right: 20px;
    }
    @include max($sm) {
      width: 100px;
      flex: 0 0 100px;
    }
    a {
      display: block;
      line-height: 0;
    }
    img {
      border-radius: 10px;
    }
  }

  .card-body {
    flex: 1;
    .card-title {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 20px;
      @include max($lg) {
        margin-bottom: 10px;
        font-size: 20px;
      }
    }
    .card-location {
      margin-bottom: 35px;
      @include max($huge) {
        margin-bottom: 20px;
      }
      @include max($lg) {
        margin-bottom: 15px;
      }
    }

    .btn {
      min-width: 164px;
      @include max($lg) {
        min-width: 0;
      }
    }
  }
}
