/***** Footer *****/

.footer {
  position: relative;
  z-index: 1;
  background: $dark;
  padding: 45px 0 26px;
  color: #fff;

  a {
    color: #fff;
    &:hover {
      opacity: 0.8;
    }
  }
  .container {
    max-width: 1820px;
  }
  .footer-i {
    display: flex;
    flex-wrap: wrap;
  }

  h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .footer-l {
    width: 400px;
    flex: 0 0 400px;
    padding-right: 50px;
    @include max($huge) {
      width: 45%;
      flex: 0 0 45%;
      order: 1;
    }
    @include max($sm) {
      width: 100%;
      flex: 0 0 100%;
      padding: 0;
      margin-bottom: 40px;
    }
    .logo {
      line-height: 0;
      margin-bottom: 50px;
      @include max($huge) {
        margin-bottom: 30px;
      }
      @include max($lg) {
        margin-bottom: 20px;
      }
      img {
        @include max($huge) {
          width: 200px;
        }
        @include max($lg) {
          width: 150px;
        }
      }
    }

    .copy {
      font-size: 16px;
    }
  }

  .footer-c {
    flex: 1;
    padding-right: 100px;
    display: flex;
    flex-wrap: wrap;
    @include max($huge) {
      order: 3;
      margin-top: 40px;
      padding: 0;
    }

    .footer-links {
      width: 45%;
      flex: 0 0 45%;
      padding-right: 40px;
      @include max($sm) {
        width: 100%;
        flex: 0 0 100%;
        padding: 0 0 30px;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          padding: 0;
          margin: 0;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }

      .social {
        margin-top: 14px;
        display: flex;
        gap: 11px;
        a {
        }
      }
    }

    .footer-locations {
      flex: 1;
      h3 {
      }

      ul {
        columns: 2;
        column-gap: 40px;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          padding: 0;
          margin: 0;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .footer-r {
    width: 620px;
    flex: 0 0 620px;
    @include max($huge) {
      width: 55%;
      flex: 0 0 55%;
      order: 2;
    }
    @include max($sm) {
      width: 100%;
      flex: 0 0 100%;
    }

    form {
      .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -6px;
        .col-6 {
          padding: 0 6px;
          width: 50%;
          flex: 0 0 50%;
          @include max($xxs) {
            width: 100%;
            flex: 0 0 100%;
          }
        }
      }
      .form-group {
      }
      .form-control {
        margin-bottom: 21px;
        border-radius: 12px;
        padding: 2px 10px;
        line-height: 31px;
        height: 37px;
        color: #fff;
        border-color: $green;
        background: none;
      }
      textarea.form-control {
        height: 71px;
      }

      .btns {
        display: flex;
        justify-content: flex-end;
        .btn {
          min-width: 174px;
        }
      }
    }
  }
}
