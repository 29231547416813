/***** Layout *****/

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1620px;
  @include max($huge) {
    padding: 0 30px;
  }
  @include max($lg) {
    padding: 0 20px;
  }
  &.container--wide {
    max-width: 1920px;
  }
}

.main {
  margin-top: 95px;
  padding: 103px 0 72px;
  @include max($huge) {
    margin-top: 65px;
    padding: 60px 0;
  }
  @include max($lg) {
    margin-top: 50px;
    padding-top: 40px;
  }
}
